module.exports = {
  siteTitle: 'Nextcell Medical', // <title>
  manifestName: 'Highlights',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#61cabf',
  manifestThemeColor: '#61cabf',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/nextcell-logo-small.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'We\'ll Be Back Soon',
  subHeading: 'Our site is undergoing a redesign, but we’ll be back in no time.',
  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/nextcell/',
    },
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://www.youtube.com/channel/UCJkk8srHiGDjHKDaU7XwYZA',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/nextcellmedical',
    },
  ],
};
