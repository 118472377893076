import React from 'react';
import Scroll from './Scroll';
import config from '../../config';
import logoPic from '../assets/img/nextcell-medical-logo.svg';

export default function Header() {
  return (
    <section id="header">
      <img src={logoPic} alt="Nextcell Medical" style={{
        maxWidth: '250px',
        width: '100%',
        height: 'auto',
        margin: '4rem auto 4rem auto',
        verticalAlign: 'top',
        display: 'inherit'
      }} />
      <header className="major">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <div style={{margin: "2rem"}}>
        <Scroll type="id" element="one">
          <a href="#one" className="button primary">
            Get in Touch
          </a>
        </Scroll>
        </div>
      </header>
    </section>
  );
}
